import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Grid, Typography, useTheme } from '@material-ui/core';

import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

export const pageQuery = graphql`
    query usecasePage($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
                image {
                    childImageSharp {
                        fixed(height: 200, quality: 100) {
                            ...GatsbyImageSharpFixed_noBase64
                            src
                        }
                    }
                }
                duration
                size
                dataSources
                team {
                    name
                    position
                    image {
                        childImageSharp {
                            fixed(height: 180, quality: 100) {
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UsecasePage = (props) => {
    const {
        location,
        data: { markdownRemark: entry },
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    const studyInfo = [
        {
            title: 'Participation duration',
            value: entry.frontmatter.duration,
            icon: 'alarm-clock',
        },
        {
            title: 'Sample size',
            value: entry.frontmatter.size,
            icon: 'users',
        },
        {
            title: 'Data sources',
            value: entry.frontmatter.dataSources,
            icon: 'database',
        },
    ];

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title={entry.frontmatter.title}
            description={entry.frontmatter.description}
            avatarImage={entry.frontmatter.image}
            metaImageSrc={entry.frontmatter.image.childImageSharp.fixed.src}
        >
            <>
                {/* eslint-disable-next-line react/no-danger */}
                <article dangerouslySetInnerHTML={{ __html: entry.html }} />
                {entry.frontmatter.duration && (
                    <>
                        <Typography variant="h2" className={classes.headline}>
                            Study Profile
                        </Typography>
                        <Box component="div">
                            <Grid container>
                                {studyInfo.map((info) => (
                                    <Grid item xs={12} key={info.title} className={classes.studyInfoWrapper}>
                                        <Grid container alignItems="baseline" spacing={1} alignContent="center" wrap="nowrap">
                                            <Grid item className={classes.studyInfoIconWrapper}>
                                                <FontAwesomeIcon
                                                    icon={['fad', info.icon]}
                                                    color={theme.palette.logo.blue.main}
                                                    size="lg"
                                                    fixedWidth
                                                />
                                            </Grid>
                                            <Grid item container alignItems="baseline">
                                                <Grid item xs={12} md={4}>
                                                    <Typography variant="h5" component="h3" className={classes.studyInfoTitle}>
                                                        {`${info.title}:`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body1">{info.value}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                )}
                <Typography variant="h2" color="textSecondary" className={classes.headline}>
                    Research Team
                </Typography>
                <Box component="div">
                    {entry.frontmatter.team.map((teamMember, teamIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid container alignItems="center" key={teamIndex} className={classes.teamWrapper}>
                            <Grid item className={classes.teamImageWrapper}>
                                <Avatar src={teamMember.image.childImageSharp.fixed.src} className={classes.teamImage} />
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Typography variant="h4" component="h3" className={classes.teamMemberName}>
                                    {teamMember.name}
                                </Typography>
                                {teamMember.position.map((teamMemberPositionLine, positionIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Typography variant="body1" key={positionIndex}>
                                        {teamMemberPositionLine}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </>
        </BigContentHeaderLayout>
    );
};

UsecasePage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                duration: PropTypes.string,
                size: PropTypes.string,
                dataSources: PropTypes.string,
                team: PropTypes.oneOfType([PropTypes.array]),
                image: PropTypes.oneOfType([PropTypes.object]),
            }),
            html: PropTypes.string,
        }),
    }).isRequired,
};

export default UsecasePage;
