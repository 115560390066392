import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    headline: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    },
    studyInfoWrapper: {
        marginBottom: theme.spacing(1.5),
    },
    studyInfoIconWrapper: {
        marginRight: theme.spacing(1),
    },
    studyInfoTitle: {
        fontWeight: '600',
    },
    teamWrapper: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    teamImageWrapper: {
        boxSizing: 'border-box',
        paddingLeft: theme.spacing(3),
        width: 350,
        [theme.breakpoints.down('lg')]: {
            width: 200,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    teamImage: {
        display: 'inline-block',
        width: 180,
        height: 180,
        [theme.breakpoints.down('lg')]: {
            width: 140,
            height: 140,
        },
        [theme.breakpoints.down('md')]: {
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
        },
    },
    teamMemberName: {
        color: theme.palette.logo.blue.main,
    },
}));
